<template>
  <div class="body">
    <div v-if="isLoading" class="loading-container">
      <img src="@/assets/loading.gif" alt="Loading" class="loading-icon" />
    </div>
    <section class="section" v-else>
      <div class="container">
        <div class="d-flex w-100 mt-4 mb-3 px-2">
          <a
            @click="goBack"
            class="text-decoration-none text-weight-bold text-dark text-1rem"
          >
            <font-awesome-icon
              icon="fa-solid fa-chevron-left"
              size="lg"
              class="icon-xl mr-3"
            ></font-awesome-icon>
            Isi data {{ determineType(isChoice) }}
          </a>
        </div>
        <Form
          class="text-left"
          @submit="handleAdd"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div v-if="!successful">
            <div class="form-group" v-show="false">
              <label class="form-label">Pilih Kategori Pilihan</label>
              <div class="selectgroup selectgroup-pills">
                <Field
                  name="category"
                  as="select"
                  class="form-control category-select"
                  :class="{ 'is-invalid': errors.category }"
                  v-model="this.selected_category_id"
                >
                  <option value="0">Tambah Pilihan</option>
                  <option
                    v-for="(itemCategory, itemIndex) in category"
                    :key="itemIndex"
                    :value="itemCategory.id"
                  >
                    {{ itemCategory.name }}
                  </option>
                </Field>
              </div>
              <label
                v-if="errors.category"
                class="mt-1"
                style="font-size: 0.875em; color: #dc3545"
                >{{ errors.category }} !</label
              >
            </div>
            <div class="form-group" v-show="selected_category_id == 0">
              <label class="text-dark" for="categoryName">Nama Category</label>
              <Field
                id="categoryName"
                name="categoryName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.categoryName }"
                v-model="this.categoryName"
              />
              <ErrorMessage name="categoryName" class="invalid-feedback" />
            </div>
            <div class="my-2">
              <label
                class="text-dark"
                style="font-weight: 600; font-size: 12px; letter-spacing: 0.5px"
                for="photo"
                >Foto {{ determineType(isChoice) }}</label
              >
              <div class="wrapper">
                <div class="photo_submit-container">
                  <div class="photo_submit-container">
                    <label
                      class="photo_submit js-photo_submit1"
                      ref="uploadBtn"
                      :style="this.items.img ? `background-image: url(${this.items.img})` : ''" style="background-size: cover; background-position: center"
                    >
                      <Field
                        ref="fileInput"
                        class="photo_submit-input js-photo_submit-input"
                        type="file"
                        accept="image/*"
                        name="image"
                        @change="uploadImage"
                      />
                      <span v-if="this.items.img == null" class="photo_submit-plus"></span>
                      <span
                        class="photo_submit-delete"
                        @click="deleteBtn"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <label
              v-if="errors.image"
              class="mt-1"
              style="color: #dc3545; font-size: 0.875em"
              >{{ errors.image }}</label
            >
            <!-- <div class="form-group">
                            <label class="custom-switch mt-2">
                                <Field v-model="isChoice" name="isChoice" type="checkbox" class="custom-switch-input"
                                    :value="true" />
                                <span class="custom-switch-indicator" ref="checkboxAvailable"></span>
                                <span class="custom-switch-description">{{ determineType(isChoice) }}</span>
                            </label>
                        </div> -->
            <div class="form-group">
              <label class="text-dark" for="name"
                >Nama {{ determineType(isChoice) }}</label
              >
              <Field
                id="name"
                name="name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
                v-model="this.items.name"
              />
              <ErrorMessage name="name" class="invalid-feedback" />
            </div>
            <div class="form-group">
              <label class="text-dark" for="price"
                >Harga {{ determineType(isChoice) }} ( Pastikan harga
                {{ determineType(isChoice) }} sudah termasuk pajak )</label
              >
              <label style="color: #f9b93d" for="price"
                >*Jika harga tidak berbeda maka isi dengan nominal 0</label
              >
              <div class="row justify-content-center">
                <div
                  class="col-1 px-1 py-2 border-top border-bottom border rounded"
                >
                  Rp
                </div>
                <Field
                  id="price"
                  name="price"
                  type="text"
                  class="col-10 ml-2 form-control"
                  :class="{ 'is-invalid': errors.price }"
                  v-model="formattedPrice"
                />
                <ErrorMessage name="price" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group">
              <label class="text-dark" for="description"
                >Deskripsi {{ determineType(isChoice) }}</label
              >
              <Field
                id="description"
                name="description"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.description }"
                v-model="this.items.description"
              />
              <ErrorMessage name="description" class="invalid-feedback" />
            </div>
            <div class="w-100 mt-3">
              <div class="row">
                <div class="col-12">
                  <button
                    :disabled="loading"
                    type="submit"
                    class="btn btn-lg text-1rem btn-block bg-moopo text-white rounded-20"
                  >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Simpan
                  </button>
                </div>
                <div class="col-12 mt-2" v-if="this.items.id">
                  <a
                    @click="deleteItem(items.id)"
                    class="btn btn-lg text-1rem btn-block btn-danger text-white rounded-20"
                  >
                    Hapus
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </section>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from "axios";

export default {
  name: "ChoiceOwner",
  props: ["item"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const MAX_FILE_SIZE = 1048576; //1MB
    const validFileExtensions = { image: ["jpg", "png", "jpeg"] };

    function isValidFileType(fileName, fileType) {
      return (
        fileName &&
        validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
      );
    }

    const schema = yup.object().shape({
      category: yup.string().when({
        is: () => {
          if (this.isChoice == "addon") {
            return true;
          } else if (this.typeItem == "service") {
            return true;
          } else return false;
        },
        then: (schema) => schema.required("Kategori pilihan harus diisi"),
      }),
      categoryName: yup.string().when({
        is: () => {
          if (this.selected_category_id == 0) {
            return true;
          } else return false;
        },
        then: (schema) => schema.required("Nama Kategori pilihan harus diisi"),
      }),
      image: yup.mixed().when({
        is: () => {
          if (this.isChoice === true && this.items.img == null) {
            return true;
          } else return false;
        },
        then: (schema) =>
          schema
            .test(
              "is-valid-type",
              "Foto harus diisi (jpg, jpeg atau png)",
              (value) =>
                isValidFileType(value && value.name.toLowerCase(), "image")
            )
            .test(
              "is-valid-size",
              "Maksimal ukuran gambar dibawah 1MB",
              (value) => value && value.size <= MAX_FILE_SIZE
            ),
      }),
      is_choice: yup.string().when({
        is: () => {
          return this.isChoice == null;
        },
        then: (schema) => schema.required("Pilih sebagai Pilihan atau Add On"),
      }),
      name: yup.string().required("Nama harus diisi"),
      price: yup.string().required("Harga Barang harus diisi"),
      description: yup.string().required("Deskripsi harus diisi"),
    });

    return {
      isLoading: true,
      isChoice: true,
      items: [],
      image: null,
      fileInput: null,
      uploadBtn: null,
      category: [],
      selected_category_id: 0,
      typeItem: "product",
      categoryName: "",
      schema,
    };
  },
  created() {
    if (localStorage.getItem("token") === null) {
      this.$router.push("/login");
    }
    window.addEventListener("popstate", this.goBack);
  },
  mounted() {
    const currentURL = window.location.href;
    if (currentURL.includes("product")) {
      this.typeItem = "product";
    } else {
      this.typeItem = "service";
    }

    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === "function";
    };

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };

    this.fetchCategoryData();

    // const updatedQuery = { ...this.$route.query };
    this.isChoice =
      this.$route.query.is_choice.toString() == "pilihan" ? true : false;
    this.selected_category_id = this.$route.query.category_id
      ? this.$route.query.category_id
      : 0;
    // updatedQuery.is_choice = (this.$route.query.is_choice.toString() == 'false') ? 'addon' : 'pilihan';
    // this.$router.replace({ query: updatedQuery });

    if (this.$route.params.id.length) {
      this.fetchData();
    } else {
      this.isLoading = false;
    }
  },
  watch: {
    isChoice: {
      handler() {
        const updatedQuery = { ...this.$route.query };
        updatedQuery.is_choice = this.isChoice == true ? "pilihan" : "addon";
        this.$router.replace({ query: updatedQuery });
      },
      deep: true,
    },
  },
  computed: {
    formattedPrice: {
      get() {
        return this.formatToCurrency(this.items.price);
      },
      set(value) {
        this.items.price = this.unformatPrice(value);
      },
    },
  },
  methods: {
    formatPrice(event) {
      const value = event.target.value;
      const numericValue = this.unformatPrice(value);
      this.items.price = this.formatToCurrency(numericValue);
    },
    unformatPrice(value) {
      return value.replace(/\./g, "").replace(/[^0-9]/g, "");
    },
    formatToCurrency(value) {
      const numberValue = Number(value);
      return isNaN(numberValue) ? "" : numberValue.toLocaleString("id-ID");
    },
    goBack() {
      if (this.$route.params.id.length) {
        if (
          this.$route.query.is_choice == "true" ||
          this.$route.query.is_choice == "pilihan"
        ) {
          this.$router.push(
            "/owner/choice-list/" +
              this.$route.params.category +
              "/" +
              this.$route.params.itemId +
              "?is_choice=true"
          );
        } else {
          this.$router.push(
            "/owner/choice-list/" +
              this.$route.params.category +
              "/" +
              this.$route.params.itemId +
              "?is_choice=false"
          );
        }
      } else {
        if (
          this.$route.query.is_choice == "true" ||
          this.$route.query.is_choice == "pilihan"
        ) {
          this.$router.push(
            "/owner/choice-list/" +
              this.$route.params.category +
              "/" +
              this.$route.params.itemId +
              "?is_choice=true"
          );
        } else {
          this.$router.push(
            "/owner/choice-list/" +
              this.$route.params.category +
              "/" +
              this.$route.params.itemId +
              "?is_choice=false"
          );
        }
      }
    },
    fetchCategoryData() {
      // const token = localStorage.getItem("token");
      // axios
      //     .get(this.mainUrl + "/api/moopo/v2/owner/category", {
      //         headers: {
      //             Authorization: `Bearer ${token}`,
      //         },
      //         params: {
      //             from: this.typeItem,
      //             is_choice: true
      //         },
      //     })
      //     .then((res) => {
      //         console.log('cat', res)
      //         this.category = res.data;
      //     })
      //     .catch((err) => {
      //         if (err.response.status == 401) {
      //             this.$router.push("/login");
      //         }
      //     });
    },
    fetchData() {
      this.isLoading = true;

      // let _url = this.mainUrl + "/api/moopo/v2/owner/item/" + this.$route.params.itemId + "/choice/show";
      let _url =
        this.mainUrl + "/api/moopo/v2/owner/item/" + this.$route.params.id;

      const token = localStorage.getItem("token");
      axios
        .get(_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("res api", res);
          this.items = res.data;
          this.items.description = res.data.desc;
          this.isChoice = true;
          if (res.data.img) {
            if (this.$refs.uploadBtn !== undefined) {
              this.$refs.uploadBtn.setAttribute(
                "style",
                `background-image: url('${res.data.img}');`
              );
              this.$refs.uploadBtn.classList.add("photo_submit--image");
            }
            this.isLoading = false;
          } else this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    uploadImage(e) {
      this.fileInput = e.target;
      this.uploadBtn = this.fileInput.parentNode;
      const reader = new FileReader();

      reader.onload = (e) => {
        this.uploadBtn.setAttribute(
          "style",
          `background-image: url('${e.target.result}');`
        );
        this.uploadBtn.classList.add("photo_submit--image");
        this.fileInput.setAttribute("readonly", "readonly");
      };

      if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);

      this.image = e.target.files[0];
    },
    deleteBtn(e) {
      this.uploadBtn.removeAttribute("style");
      this.uploadBtn.classList.remove("photo_submit--image");
      e.target.value = "";
      setTimeout(() => {
        this.fileInput.removeAttribute("disabled", "disabled");
      }, 200);
    },
    deleteItem(id) {
      const token = localStorage.getItem("token");
      axios
        .delete(this.mainUrl + "/api/moopo/v2/owner/item/" + id, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status) {
            this.$router.push(
              "/owner/choice-list/" +
                this.$route.params.category +
                "/" +
                this.$route.params.itemId +
                "?is_choice=" +
                this.isChoice
            );
          }
        })
        .catch((err) => {
          // console.log(err.response.data.message);
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    handleAdd(data) {
      this.isLoading = true;
      const cleanedString = data.price.replace(/\./g, "");
      const numberValue = Number(cleanedString);
      let formData = {
        image: this.image,
        name: data.name,
        is_choice: this.isChoice,
        price: numberValue,
        description: data.description,
        desc: data.description,
        outlet_id: JSON.parse(localStorage.getItem("outlet")).id,
        category_id:
          this.categoryName == ""
            ? this.selected_category_id
            : this.categoryName,
        // "parent_id": this.items.parent_id ?? null,
        // "reference_parent_id": this.items.reference_parent_id ?? null,
        // "type": this.typeItem,
      };
      console.log(formData);

      const token = localStorage.getItem("token");

      if (this.$route.params.id.length) {
        console.log("update");
        formData["_method"] = "PUT";

        let _url =
          this.mainUrl +
          "/api/moopo/v2/owner/item/" +
          this.$route.params.itemId +
          "/choice/" +
          this.$route.params.id +
          "/update";
        // let _url = this.mainUrl + "/api/moopo/v2/owner/item/" + this.$route.params.id;

        axios
          .post(_url, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
            this.isLoading = false;
            this.$router.push(
              "/owner/choice-list/" +
                this.$route.params.category +
                "/" +
                this.$route.params.itemId +
                "?is_choice=" +
                this.isChoice
            );
          })
          .catch((err) => {
            this.isLoading = false;
            if (err.response.status == 401) {
              this.$router.push("/login");
            }
            console.log(err);
          });
      } else {
        console.log("store", formData);
        let _url =
          this.mainUrl +
          "/api/moopo/v2/owner/item/" +
          this.$route.params.itemId +
          "/choice/store";

        axios
          .post(_url, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
            this.$router.push(
              "/owner/choice-list/" +
                this.$route.params.category +
                "/" +
                this.$route.params.itemId +
                "?is_choice=" +
                this.isChoice
            );
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.$router.push("/login");
            }
            console.log(err);
          });
      }
    },
    determineType(isChoice) {
      return isChoice ? " Pilihan" : " Tambahan";
    },
  },
};
</script>

<style scoped>
/* Your scoped styles go here */

.upload-btn {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center;
}

.selectgroup-button {
  height: 45px;
  line-height: 45px;
}

.selectgroup-input:focus + .selectgroup-button,
.selectgroup-input:checked + .selectgroup-button {
  background-color: #5b3825;
  color: white;
  z-index: 1;
}
</style>
