<template>
  <div class="body" style="margin-bottom: 7vh">
    <div v-if="isLoading" class="loading-container">
      <img src="@/assets/loading.gif" alt="Loading" class="loading-icon" />
    </div>
    <section class="section">
      <div class="container p-0">
        <div class="d-flex w-100 my-4 px-4">
          <a
            @click="goBack"
            class="text-decoration-none text-weight-bold text-dark text-1rem"
          >
            <font-awesome-icon
              icon="fa-solid fa-chevron-left"
              size="lg"
              class="icon-xl mr-3"
            ></font-awesome-icon>
            {{ typeItem == "service" ? "Pilihan / Tambahan" : "Pilihan" }}
          </a>
        </div>
        <ul
          v-if="typeItem == 'service'"
          class="col-12 p-0 nav nav-tabs mb-2"
          id="myTab"
          role="tablist"
        >
          <li class="col-6 nav-item">
            <a
              :class="
                index == 0
                  ? 'text-moopo-important bg-moopo-secondary rounded-10'
                  : 'text-gray-important'
              "
              class="nav-link text-center"
              ref="tabPending"
              @click="moveTab(0)"
            >
              Pilihan
            </a>
          </li>
          <li class="col-6 nav-item">
            <a
              :class="
                index == 1
                  ? 'text-moopo-important bg-moopo-secondary rounded-10'
                  : 'text-gray-important'
              "
              class="nav-link text-center"
              ref="tabProses"
              @click="moveTab(1)"
            >
              Tambahan
            </a>
          </li>
        </ul>
        <!-- <ul v-if="typeItem == 'service'" class="col-12 nav nav-tabs text-moopo-important mb-2" id="myTab"
                    role="tablist">
                    <li class="col-6 nav-item text-moopo-important">
                        <a class="nav-link text-center text-moopo-important" ref="tabPending" @click="moveTab(0)">
                            Pilihan
                        </a>
                    </li>
                    <li class="col-6 nav-item text-moopo-important">
                        <a class="nav-link text-center text-moopo-important" ref="tabProses" @click="moveTab(1)">
                            Tambahan
                        </a>
                    </li>
                </ul> -->
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active pt-0">
            <div class="container">
              <div
                v-if="
                  !items.length > 0 &&
                  !choiceCategories.length > 0 &&
                  !isLoading
                "
                class="empty-data center-screen text-center"
              >
                <div class="empty-data-content">
                  <h5 class="text-moopo">Datanya Masih Kosong Nih!</h5>
                  <p>Silahkan jelajahi fitur lainnya</p>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12 px-2" v-if="index == 0">
                  <div
                    v-for="(category, categoryIndex) in items"
                    :key="categoryIndex"
                  >
                    <div class="row border pb-1 mb-2 rounded-10">
                      <div
                        class="row col-12 px-3 d-flex justify-content-between pt-2"
                      >
                        <label class="pl-3 text-dark text-115rem">{{
                          category.name
                        }}</label>
                        <div class="row">
                          <a
                            @click="addItemChoice(category.id)"
                            class="btn-moopo py-1 px-3 mr-1 rounded-20"
                          >
                            Tambah
                          </a>
                          <a
                            @click="deleteItemChoice(category.id)"
                            class="btn-danger py-1 px-3 rounded-20"
                          >
                            Hapus
                          </a>
                        </div>
                      </div>
                      <div
                        class="col-12 mt-2 col-card-menu"
                        v-for="(item, itemIndex) in category.items"
                        :key="itemIndex"
                      >
                        <div class="card rounded-10 shadow-primary">
                          <div class="card-body p-1">
                            <div class="row">
                              <div class="col-5">
                                <img
                                  class="img-responsive pt-2 w-100 h-100 rounded-20 img-menu"
                                  :src="item.img"
                                  alt=""
                                  :onerror="defaultFood"
                                />
                              </div>
                              <div class="col-7 p-0 py-2">
                                <div class="mw-60">
                                  <h6 class="text-dark text-weight-800 mb-0">
                                    {{ item.name }}
                                  </h6>
                                </div>
                                <p
                                  class="text-dark text-75rem font-weight-normal text-ellipsis w-80 mb-0"
                                >
                                  {{ item.description }}
                                </p>
                                <p class="text-dark text-75rem mb-0">
                                  {{ $numFormat(item.price) }}
                                </p>
                                <div class="card-action text-right">
                                  <a
                                    @click.stop="
                                      editItemChoice(item.id, category.id)
                                    "
                                  >
                                    <font-awesome-icon
                                      icon="fa-solid fa-pencil"
                                      class="text-dark"
                                    ></font-awesome-icon>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-12 py-3">
                                    <div class="input-group">
                                        <input v-model="searchItem" type="text" class="form-control search-input"
                                            :placeholder="(index == 0) ? 'Cari Pilihan' : 'Add-On'" />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" type="button">
                                                <font-awesome-icon
                                                    :icon="['fas', 'magnifying-glass']"></font-awesome-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div> -->
                <div class="col-12" v-if="index == 1">
                  <div
                    class="col-card-menu"
                    v-for="(item, itemIndex) in doFilter"
                    :key="itemIndex"
                  >
                    <div
                      class="card rounded-10 shadow-primary"
                      :class="{ 'onclick-border': item.is_selected }"
                    >
                      <div
                        class="card-body p-1"
                        @click="addItem(item.id, item.is_selected)"
                      >
                        <div :class="{ row: index == 0 }">
                          <div class="col-5" v-if="index == 0">
                            <img
                              class="img-responsive pt-2 w-100 h-100 rounded-20 img-menu"
                              :src="item.img"
                              alt=""
                              :onerror="defaultFood"
                            />
                          </div>
                          <div
                            :class="
                              index == 0
                                ? 'col-7 p-0 py-2'
                                : 'col-12 p-0 py-2 px-3'
                            "
                          >
                            <div class="mw-60">
                              <p class="text-80rem font-weight-normal mb-0">
                                {{ item.category }}
                              </p>
                              <h6 class="text-dark text-weight-800 mb-0">
                                {{ item.name }}
                              </h6>
                            </div>
                            <p
                              class="text-dark text-75rem font-weight-normal text-ellipsis w-80 mb-0"
                            >
                              {{ item.description }}
                            </p>
                            <p
                              class="mb-0 text-moopo text-80rem"
                              :class="
                                item.is_selected ? 'text-danger' : 'text-moopo'
                              "
                            >
                              {{
                                item.is_selected
                                  ? "Hilangkan tambahan ini"
                                  : "Gunakan tambahan ini"
                              }}
                            </p>
                            <p class="text-dark text-75rem mb-0">
                              {{ item.moopo_discounted_price }}
                            </p>
                            <div class="card-action text-right">
                              <a @click.stop="edit(item.id)">
                                <font-awesome-icon
                                  icon="fa-solid fa-pencil"
                                  class="text-dark"
                                ></font-awesome-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="w-100 action-bar">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <a
            @click="addPage"
            class="btn-add bg-moopo rounded-circle text-white mb-2 float-right d-flex justify-content-center align-items-center"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-plus"
              size="2xl"
            ></font-awesome-icon>
          </a>
        </div>
        <!-- <div class="col-12">
                    <a :href='"/owner/subcategories/choice?from=" + typeItem'
                        class="btn btn-lg text-1rem btn-block bg-moopo text-white rounded-20">
                        Sub Kategori
                    </a>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { PROD_URL } from "@/utils/url";
import axios from "axios";
export default {
  name: "ProductComponent",
  data() {
    return {
      isLoading: false,
      items: [],
      index: 0,
      searchItem: "",
      typeItem: "product",
      choiceCategories: [],
    };
  },
  computed: {
    doFilter() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.searchItem.toLowerCase());
      });
    },
  },
  mounted() {
    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === "function";
    };
    console.log("this", this.$route.params);

    this.choiceCategories = [
      {
        id: 1,
        name: "Pilihan 1",
        items: [
          {
            id: 1,
            category_id: 1,
            name: "Item Pilihan 1",
            description: "Item Pilihan 1",
            moopo_discounted_price: 10000,
            img: PROD_URL + "/default.png",
          },
          {
            id: 2,
            category_id: 1,
            name: "Item Pilihan 2",
            description: "Item Pilihan 2",
            moopo_discounted_price: 20000,
            img: PROD_URL + "/default.png",
          },
        ],
      },
      {
        id: 2,
        name: "Pilihan 2",
        items: [
          {
            id: 1,
            category_id: 2,
            name: "Item Pilihan 1",
            description: "Item Pilihan 1",
            moopo_discounted_price: 10000,
            img: PROD_URL + "/default.png",
          },
          {
            id: 2,
            category_id: 2,
            name: "Item Pilihan 2",
            description: "Item Pilihan 2",
            moopo_discounted_price: 20000,
            img: PROD_URL + "/default.png",
          },
        ],
      },
    ];
    localStorage.setItem(
      "choiceCategories",
      JSON.stringify(this.choiceCategories)
    );

    const currentURL = window.location.href;
    if (currentURL.includes("product")) {
      this.typeItem = "product";
    } else {
      this.typeItem = "service";
    }

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };

    if (this.$route.query.is_choice !== undefined) {
      if (this.$route.query.is_choice.toString() == "true") {
        const currentQuery = { ...this.$route.query };
        // delete currentQuery['is_choice'];
        // this.$router.replace({ query: currentQuery });
        // const updatedQuery = { ...this.$route.query };
        currentQuery.is_choice = "true";
        this.$router.replace({ query: currentQuery });
        this.moveTab(0);
      } else {
        const currentQuery = { ...this.$route.query };
        // delete currentQuery['is_choice'];
        // this.$router.replace({ query: currentQuery });
        // const updatedQuery = { ...this.$route.query };
        currentQuery.is_choice = "false";
        this.$router.replace({ query: currentQuery });
        this.moveTab(1);
      }
    } else {
      this.moveTab(0);
    }
  },
  created() {
    if (localStorage.getItem("token") === null) {
      this.$router.push("/login");
    }
    window.addEventListener("popstate", this.goBack);
  },
  methods: {
    addItemChoice(id) {
      const query = "?is_choice=pilihan&category_id=" + id;
      if (this.$route.params.itemId.length) {
        this.$router.push(
          "/owner/" +
            this.typeItem +
            "/" +
            this.$route.params.itemId +
            "/choice" +
            query
        );
      }
    },
    deleteItemChoice(id) {
      let _url =
        this.mainUrl +
        "/api/moopo/v2/owner/item/" +
        this.$route.params.itemId +
        "/choice/category/" +
        id +
        "/delete";

      const token = localStorage.getItem("token");

      axios
        .delete(_url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("delete", id);
    },
    editItemChoice(id, categoryId) {
      const query = "?is_choice=pilihan&category_id=" + categoryId;
      this.$router.push(
        "/owner/" +
          this.typeItem +
          "/" +
          this.$route.params.itemId +
          "/choice/" +
          id +
          query
      );
    },
    addPage() {
      if (this.index == 0) {
        if (this.items.length == 2 && this.typeItem == "product") {
          this.$toast.show("Maksimal Pilihan hanya 2 Kategori", {
            position: "bottom",
          });
        } else {
          if (this.items.length == 1 && this.typeItem == "service") {
            this.$toast.show("Maksimal Pilihan hanya 1 Kategori", {
              position: "bottom",
            });
          } else {
            const query =
              this.index == 0 ? "?is_choice=pilihan" : "?is_choice=addon";
            if (this.$route.params.itemId.length) {
              this.$router.push(
                "/owner/" +
                  this.typeItem +
                  "/" +
                  this.$route.params.itemId +
                  "/choice" +
                  query
              );
            }
          }
        }
      } else {
        this.$router.push("/owner/serviceform?from=addon");
      }
    },
    defaultFood(event) {
      event.target.src = require("@/assets/banner/main/moopo.jpeg");
    },
    moveTab(tab) {
      this.items = [];
      const updatedQuery = { ...this.$route.query };
      updatedQuery.is_choice = tab == 0 ? "true" : "false";
      this.$router.replace({ query: updatedQuery });

      this.index = tab;
      // this.isLoading = true;

      let _url =
        this.mainUrl +
        "/api/moopo/v2/owner/item/" +
        this.$route.params.itemId +
        "/choice";

      const token = localStorage.getItem("token");
      axios
        .get(_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            is_choice: tab == 0 ? true : false,
            id: JSON.parse(localStorage.getItem("outlet")).id,
            per_page: 0,
          },
        })
        .then((res) => {
          console.log("resdata", res.data);
          this.items = res.data.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    handleScroll() {
      const scrollableHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrolledDistance = window.scrollY;
      const scrollThreshold = 100;

      if (scrollableHeight - scrolledDistance < scrollThreshold) {
        this.isLoading = true;
        let _url =
          this.mainUrl +
          "/api/moopo/v2/owner/item/" +
          this.$route.params.itemId +
          "/choice";
        const token = localStorage.getItem("token");
        axios
          .get(_url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              // 'is_choice': (tab == 0) ? true : false,
              id: JSON.parse(localStorage.getItem("outlet")).id,
              per_page: 10,
            },
          })
          .then(this.handleRequest)
          .catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
              this.$router.push("/login");
            }
          });
        // if (this.nextPage != null) {
        //   this.isLoading = true;
        //   let _url = this.mainUrl + "/api/moopo/v2/owner/item/" + this.$route.params.item + "/choice";
        //   this.nextPage = null;
        //   const token = localStorage.getItem("token");
        //   axios
        //     .get(_url, {
        //       headers: {
        //         Authorization: `Bearer ${token}`,
        //       },
        //       params: {
        //         'is_choice': (tab == 0) ? true : false,
        //         'id': JSON.parse(localStorage.getItem("outlet")).id,
        //         'per_page': 10,
        //       },
        //     })
        //     .then(this.handleRequest)
        //     .catch((err) => {
        //       console.log(err);
        //       if (err.response.status == 401) {
        //         this.$router.push("/login");
        //       }
        //     });
        // }
      }
    },
    addItem(id, is_selected) {
      this.isLoading = true;

      let formData = {
        is_choice: this.index == 0 ? true : false,
        is_selected: !is_selected,
      };

      console.log(formData);

      const token = localStorage.getItem("token");
      let _url =
        this.mainUrl +
        "/api/moopo/v2/owner/item/" +
        this.$route.params.itemId +
        "/choice/" +
        id +
        "/sync";

      axios
        .put(_url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("res api");
          console.log(res.data);
          // this.items = res.data.data;
          this.moveTab(this.index);
          // this.isLoading = false;
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    deleteData(id) {
      let type = this.index == 0 ? "Menu Pilihan" : "Add On";
      this.$swal({
        title: "Hapus " + type,
        text: "Apakah anda yakin ingin menghapus " + type + " ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Batal",
        cancelButtonText: "Iya",
        customClass: {
          cancelButton: "btn btn-success",
          confirmButton: "btn btn-danger mr-3",
        },
        buttonsStyling: false,
      }).then((res) => {
        console.log(res);
        if (!res.isConfirmed) {
          const token = localStorage.getItem("token");
          axios
            .delete(
              this.mainUrl +
                "/api/moopo/v2/owner/item/" +
                this.$route.params.itemId +
                "/choice/" +
                id +
                "/delete",
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
                params: {
                  is_choice: this.index == 0 ? true : false,
                },
              }
            )
            .then((res) => {
              // console.log(res);
              if (res.status) {
                this.moveTab(this.index);
              }
            })
            .catch((err) => {
              // console.log(err.response.data.message);
              if (err.response.status == 401) {
                this.$router.push("/login");
              }
            });
        }
      });
    },
    edit(id) {
      if (this.index == 0) {
        let is_choice =
          this.index == 0 ? "?is_choice=pilihan" : "?is_choice=addon";
        this.$router.push(
          "/owner/" +
            this.typeItem +
            "/" +
            this.$route.params.itemId +
            "/choice/" +
            id +
            is_choice
        );
      } else {
        this.$router.push("/owner/serviceform/" + id + "?from=addon");
      }
    },
    goBack() {
      this.$router.replace("/owner/" + this.typeItem);
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Your scoped styles go here */
.card-action {
  position: absolute;
  top: 10px;
  right: 25px;
  width: 100%;
  height: 50px;
}

.mw-60 {
  max-width: 60%;
}

.action-bar {
  position: fixed;
  padding-bottom: 10px;
  bottom: 0;
  /* background-color: white; */
  z-index: 999;
}

.btn-add {
  height: 55px;
  width: 55px;
}

.card {
  margin-bottom: 10px;
}

.img-menu {
  max-height: 110px;
  object-fit: cover;
  object-position: center center;
}

.onclick-border {
  outline: 1px solid #5b3825;
  outline-offset: -1px;
}
</style>
